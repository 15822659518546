<template>
  <div class="turnDownOfferRecord">
    <PocCardTable>
      <div class="flexBet" style="margin-bottom: 18px">
        <div class="pcth-title-left">
          拒绝记录
        </div>
        <div>
          <el-button
            icon="el-icon-refresh-left"
            class="buttonrefresh"
            :loading="queryLoading"
            @click="refresh"
          >
          </el-button>
          <el-button
            class="buttonUnactive"
            :loading="queryLoading"
            @click="downLoadList"
          >下载全部拒绝记录</el-button>
          <el-button
            class="buttonUnactive"
            type="primary"
            :loading="queryLoading"
            @click="filterQuery"
          >查询</el-button>
        </div>
      </div>
      <PocSlider minheight="65px" maxHeight="350px" btntype="primary">
        <el-form
          :inline="true"
          :model="formInline.data"
          label-position="top"
          class="el-form elformInputBox minWidthInput"
        >
          <el-form-item label="Chinese Name">
            <el-input
              placeholder="请输入Chinese Name"
              clearable
              v-model="formInline.data.nameCn"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="English Name">
            <el-input
              placeholder="请输入Chinese Name"
              clearable
              v-model="formInline.data.nameEn"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Assigment/project (Level2)">
            <el-select
              v-model="formInline.data.level2Id"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in formOptions.level2List"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Description（Level3）">
            <el-select
              v-model="formInline.data.level3Id"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in formOptions.level3List"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Offer Date">
            <el-date-picker
              style="width: 100%"
              v-model="formOptions.dateRange"
              type="daterange"
              align="right"
              unlink-panels
              :clearable="true"
              range-separator="-"
              start-placeholder="Start Date"
              end-placeholder="End Date"
              value-format="yyyy-MM-dd"
              @change="changeDate"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="TA Owner">
            <el-select
              clearable
              v-model="formInline.data.taInHandId"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in formOptions.taOwnerList"
                :key="item.id"
                :label="item.userName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Turn Down Reason">
            <el-select
              clearable
              v-model="formInline.data.reasonId"
              filterable
              placeholder="请选择"
              class="industry-selector"
            >
              <el-option
                v-for="item in formOptions.turnDownReasonList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </PocSlider>
    </PocCardTable>
    <PocCardTable style="margin-top: 18px">
      <PocTable
        v-loading="queryLoading"
        :data="formOptions.tableData"
        size="large"
        stripe
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        :max-height="1000000000"
        ref="multipleTable"
        row-key="offerProposalId"
      >
        <el-table-column
          v-for="item in turnDownOfferRecordColumn"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :min-width="item.width"
          align="center"
        >
          <template v-slot="scope">
            <div v-if="item.key==='remark'">
              <el-tooltip
                v-if="scope.row[item.key] && scope.row[item.key].length > 60"
                popper-class="workorder-reason-popper"
                effect="dark"
                :content="scope.row[item.key]"
                placement="top">
                <span>{{ ellipsis(scope.row[item.key])}}</span>
              </el-tooltip>
              <span v-else>{{scope.row[item.key]}}</span>
            </div>
            <span v-else>
              {{scope.row[item.key]}}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="90"
          align="center"
          fixed="right"
        >
          <template v-slot="scope">
            <el-button type="text" @click="rejectReason(scope.row)" style="font-size: 12px">拒绝原因</el-button>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <turnDownOfferDialog ref="turnDownOfferDialogRef" @commit="rejectReasonCommit" />

  </div>
</template>
<script setup>

import { onMounted, reactive, ref } from "vue";
import { turnDownOfferRecordColumn } from "@/views/turnDownOfferRecord/columnConfig/turnDownOfferRecordColumn"
import TableMixin from '@/views/employee/Hooks/TableMixin'
import TurnDownOfferDialog from "@/views/turnDownOfferRecord/turnDownOfferDialog.vue";
import { getTaUserList } from "@/api/organization";
import induction from "@/api/induction"
import { downLoadFileByBlob } from "@/utils/util";
const { Message } = $PCommon

let { pageConfig, pageChange, pageSizeChange } = TableMixin(queryApi)
let queryLoading = ref(false)
let turnDownOfferDialogRef = ref()
const formOptions = reactive({
  level2List:[],
  level3List:[],
  taOwnerList:[],
  turnDownReasonList:[],
  dateRange:[],
  tableData:[],
})
const formInline = reactive({data:{
    nameCn:'',
    nameEn:'',
    level2Id:'',
    level3Id:'',
    taInHandId:'',
    reasonId:'',
    offerStartDate:'',
    offerEndDate:'',
  }
})
onMounted(() =>{
  getFormOptions()
  queryApi()
})
const ellipsis = (value)=> {
    if (!value) return "";
    if (value.length > 60) {
      return value.slice(0, 60) + "...";
    }
    return value;
  }
const getFormOptions = () => {
    getTaUserList()
      .then(({code,data})=>{
          if(code === 200){
            formOptions.taOwnerList = data || []
          }
        })
    induction.getAssigmentProjectLevel2({})
         .then(({code,data})=>{
           if (code === 200) {
             formOptions.level2List = data || []
           }
         })
    induction.getDescriptionLevel3({})
      .then(({code,data})=>{
        if (code === 200) {
          formOptions.level3List = data || []
        }
      })
    induction.dictList({codeSchema:'turn_down_offer_reason'}).then(({code,data})=>{
      if(code === 200){
        formOptions.turnDownReasonList = data || []
      }
    })
}
const refresh = () =>{
  formInline.data = {
    nameCn:'',
    nameEn:'',
    level2Id:'',
    level3Id:'',
    taInHandId:'',
    reasonId:'',
    offerStartDate:'',
    offerEndDate:'',
  }
  queryApi()
}
const downLoadList = () =>{
  queryLoading.value = true
  induction.downLoadList().then((res)=>{
    queryLoading.value = false
    if(res){
      downLoadFileByBlob(res)
    }
  })
}
const filterQuery = () =>{
  pageConfig.pageNum = 1
  queryApi()
}
const changeDate = (val) =>{
  if(val){
    formInline.data.offerStartDate = val[0] + " 00:00:00"
    formInline.data.offerEndDate = val[1] + " 23:59:59"
  }else {
    formInline.data.offerStartDate = ''
    formInline.data.offerEndDate = ''
  }
}
const rejectReason = (row) =>{
  turnDownOfferDialogRef.value.show(row)
}
const rejectReasonCommit = (val) =>{
  induction.editReason(val).then(({code,msg})=>{
    turnDownOfferDialogRef.value.close()
    if(code === 200){
      Message.success(msg)
      filterQuery()
    }
  })
}
function queryApi() {
  const params = {
    pageSize: pageConfig.pageSize,
    pageNum: pageConfig.pageNum,
    ...formInline.data
  }
  induction.turnDownList(params).then(({code,data})=>{
    if(code === 200){
      formOptions.tableData = data.list || []
      pageConfig.total = data.total
    }
  })
}
</script>
<style scoped lang="scss">
.turnDownOfferRecord{
  ::v-deep .poc-slider .poc-slider-btns{
    justify-content: flex-end;
    .el-button{
      margin-top: 28px !important;
    }
  }
  ::v-deep .el-table th.el-table__cell > .cell {
    word-wrap: break-word;
    word-break: break-word;
  }
  ::v-deep .el-card__body{
    padding-bottom: 8px !important;
  }
}
</style>
