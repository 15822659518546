import { computed, reactive } from 'vue'

export const pageConfig = reactive({
  sort: [],
  loading: false,
  pageNum: 1,
  pageSize: 10,
  total: 0
})
export const pageChange = (queryApi) => {
  return () => {
    queryApi()
  }
}
export const pageSizeChange = (queryApi) => {
  return (param) => {
    pageConfig.pageSize = param
    pageConfig.currentPage = 1
    queryApi()
  }
}

export let currentPage = computed({
  get() {
    return pageConfig.pageNum
  },
  set(n) {
    pageConfig.pageNum = n
  }
})
export default (queryApi) => {
  return {
    pageConfig,
    pageChange: pageChange(queryApi),
    pageSizeChange: pageSizeChange(queryApi),
    currentPage
  }
}
