export const turnDownOfferRecordColumn = [
  {
    key: 'englishName',
    name: 'Candidate English Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'taName',
    name: 'TA Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'offerDate',
    name: 'Offer Date',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'turnDownDate',
    name: 'Turn Down Date',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'reason',
    name: 'Turn Down Reason',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'remark',
    name: 'Remark',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 400 // 默认宽度，像素
  },
  {
    key: 'level2',
    name: 'Assignment/Project(level 2)',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 200 // 默认宽度，像素
  },
  {
    key: 'team',
    name: 'Team',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'parentFunction',
    name: 'Parent Function',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 130 // 默认宽度，像素
  },
  {
    key: 'childFunction',
    name: 'Child Function',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 130 // 默认宽度，像素
  },
  {
    key: 'title',
    name: 'Title',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 100 // 默认宽度，像素
  },
  {
    key: 'jobGrading',
    name: 'Job Grade',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 120 // 默认宽度，像素
  },
  {
    key: 'lmName',
    name: 'LM Name',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 120 // 默认宽度，像素
  },
  {
    key: 'candidatePhone',
    name: 'Candidate Phone',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'candidateEmail',
    name: 'Candidate Email',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'candidatePreviousCompanyCategory',
    name: 'Candidate\'s Previous Company Category',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 180 // 默认宽度，像素
  },
  {
    key: 'candidatePreviousCompany',
    name: 'Candidate\'s Previous Company',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'recruitingChannel',
    name: 'Recruitment Channel',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 160 // 默认宽度，像素
  },
  {
    key: 'recruitmentChannelRemark',
    name: 'Recruiting Channel-Remark',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'workingLocation',
    name: 'Working Location',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
]
